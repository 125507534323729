// Import bootstrap JS
import "bootstrap";

// Import base style
import "application/index.scss";

// Flag-icons for language switcher
import "flag-icons/index.scss";

import "components";

require("@rails/ujs").start();

const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

export default imagePath;
